/**
 * 菜单按钮权限配置文件
 */
const systemManagement = {
    'organizationMaintain': {
        edit: '100013001',
        maintenance: '100013002'
    },
    'companyMaintain': {
        add: '100014001',
        edit: '100014002',
        sync: '100014003',
        companyChang: '100014004',
        export: '100014005'
    },
    'sysCtrlParam': {
        add: '100001001',
        examine: '100001002',
        edit: '100001003',
        delete: '100001004'
    },
    'databaseManage': {
        add: '100002001',
        switchStatus: '100002002',
        manage: '100002004',
        edit: '100002005',
        delete: '100002006'
    },
    'dataInstanceManage': {
        add: '100003001',
        switch: '100003002'
    },
    'dataDictionary': {
        add: '100004001',
        sync: '0104000001',
        skipDetail: '100004003',
        edit: '100004004',
        delete: '100004005'
    },
    'mqManager': {
        add: '100005001',
        edit: '100005002',
        delete: '100005003',
        siwtchOn: '100005004',
        siwtchOff: '100005005'
    },
    'consumerMange': {
        Edit: '0602000001',
        apiConfig: '0602000002',
        checkDaily: '0602000003',
        handleDelete: '0602000004',
        siwtchOff: '0602000005',
        add: '0602000006',
        retry: '0602000007'
    },
    'mappingSync': {
        add: '100007001',
        syncEdit: '100007002',
        customSync: '100007003',
        view: '100007004',
        valueSync: '100007005'
    },
    'translationTable': {
        export: '100008001',
        edit: '100008002',
        batchSave: '100008003'
    },
    'translationDataDictionary': {
        import: '100009001',
        export: '100009002',
        edit: '100009003',
        batchSave: '100009004'
    },
    'masterDataTranslation': {
        import: '100010001',
        export: '100010002',
        edit: '100010003',
        batchSave: '100010004'
    }
};
const warehouseRelated = {};
const commodityRelated = {};
const addressRelated = {
    'addressMapping': {
        add: '0704000001',
        batchSave: '0704000002',
        delete: '0704000003'
    },
    'admLayout': {
        add: '700003001',
        calibration: '700003002',
        revise: '700003003'
    }

};
const exportSales = {
    'orgFramework': {
        add: '146600010',
        edit: '146600011',
        revise: '146600012'
    },
    'physicsWarehouse': {
        add: '12500670001',
        edit: '12500670002',
        delete: '12500670003',
        sync: '12500670004'
    },
    'loadAndUnloadGroup': {
        add: '12500680001',
        edit: '12500680002',
        delete: '12500680003',
        sync: '12500680004'
    },
    'sublibraryOrganization': {
        add: '12210002001',
        import: '12210002002',
        batchSave: '12210002003',
        batchEnable: '12210002004',
        batchDisable: '12210002005'
    },
    'sublibraryMerchandise': {
        add: '12210003001',
        batchSave: '12210003002',
        batchEnable: '12210003003',
        batchDisable: '12210003004',
        import: '12210003005'
    },
    'exportSalesOrderType': {
        add: '12210004001',
        batchSave: '12210004002',
        batchEnable: '12210004003',
        batchDisable: '12210004004'
    },
    'exportSalesOrderTypeMapping': {
        add: '12210005001',
        batchSave: '12210005002',
        batchEnable: '12210005003',
        batchDisable: '12210005004'
    }
};
const baseFunction = {};
const customerRelated = {
    'tianfuBankContract': {
        detailSave: '0606000001',
        detailApplyIflow: '0606000002',
        detailUploadFilesToTF: '0606000003',
        detailFilesTableAdd: '0606000004',
        detailUploadFilesToZHQ: '0606000005'
    },
    'contractManage': {
        rateAddPrivilege: '0707000001', // 子页签费率明细特权新增
        rateSavePrivilege: '0707000002', // 子页签费率明细特权保存
        rateDeletePrivilege: '0707000003', // 子页签费率明细特权删除
        priceMaintain: '0707000004', // 主合同计费模型和价格
        importPriceDetailS: '0707000005', // 价格表明细-特权导入明细
        deletePriceDetailS: '0707000006', // 价格表明细-特权删除价格表
        addPriceDetailS: '0707000007', // 价格表-特权新增
        openPriceDetailS: '0707000008', // 价格表-特权启用
        addNewMain: '0707000009', // 主合同新增
        deleteMain: '0707000010', // 主合同删除
        exportMain: '0707000011', // 主合同导出
        saveMain: '0707000012', // 主合同保存
        applyIflow: '0707000013', // 主合同提交审批
        replenishContract: '0707000014', // 主合同补充协议
        stopContract: '0707000015', // 主合同终止协议
        otherChange: '0707000016', // 主合同其他变更
        contractChangeSubmit: '0707000017', // 主合同变更提交
        contractApproved: '0707000018', // 主合同审批通过
        contractApprovedReject: '0707000019', // 主合同审批驳回
        // priceMaintenBtn: '0707000020', // 主合同计费模型和价格
        subAddNewFile: '0707000021', // 子页签附件明细新增
        subDeleteFile: '0707000022', // 子页签附件明细删除
        subAddNewCustomer: '0707000023', // 子页签客户明细新增
        subSaveCustomer: '0707000024', // 子页签客户明细保存
        subDeleteCustomer: '0707000025', // 子页签客户明细删除
        subImportCustomer: '0707000026', // 子页签客户明细导入
        subExportCustomer: '2024082268', // 子页签客户明细-导出
        subAddNewCompany: '0707000027', // 子页签分公司明细新增
        subDeleteCompany: '0707000028', // 子页签分公司明细删除
        subAddSiteCompany: '0707000029', // 子页签分公司明细新增服务平台
        subDeleteSiteCompany: '0707000030', // 子页签分公司明细删除服务平台
        subAddNewRate: '0707000031', // 子页签业务税率新增
        subSaveRate: '0707000032', // 子页签业务税率保存
        subDeleteRate: '0707000033', // 子页签业务税率删除
        subAddNewPrescreen: '0707000034', // 子页签预签明细新增
        subSavePrescreen: '0707000035', // 子页签预签明细保存
        subDeletePrescreen: '0707000036', // 子页签预签明细删除
        subDeleteOtherChange: '0707000037', // 子页签其他变更明细删除
        subAddNewOil: '0707000038', // 子页签油价信息新增
        subSaveOil: '0707000039', // 子页签油价信息保存
        subDeleteOil: '0707000040', // 子页签油价信息删除
        addNewPriceMain: '0707000041', // 价格表-新增
        exportPlacePriceMain: '0707000042', // 价格表-导出行政规则
        importPriceDetail: '0707000043', // 价格表明细-导入明细
        exportPriceDetail: '0707000044', // 价格表明细-导出明细
        clearPriceDetail: '0707000045', // 价格表明细-清空草稿明细
        deletePriceDetail: '0707000046', // 价格表-删除
        addNewPriceMainMD: '0707000047', // 价格表-产品中台新增
        subAddNewOpportunity: '0707000048', // 子页签分公司明细-新增商机
        subFileView: '0707000049', // 子页签-附件明细-预览
        subFileDownLoad: '0707000050', // 子页签-附件明细-下载
        subFileUpLoad: '0707000051', // 子页签-附件明细-上传
        subAddNewCompanyS: '0707000052', // 子页签分公司明细新增(CRM合同管理专员角色独有)
        subPushPrice: '0707000053', // 价格表-推送合同价格
        otherChangeBasic: '0707000054', // 主合同-其他变更-基本信息
        otherChangeDate: '0707000055', // 主合同-其他变更-合同日期
        otherChangePrice: '0707000056', // 主合同-其他变更-价格信息
        changeStandard: '0707000057', // 主合同-转标准产品合同
        subAddNewCompanyBatch: '0707000058', // 子页签-分公司明细-批量更新分公司
        contractArchive: '0707000059', // 合同归档
        contractArchivePrint: '0707000060', // 打印归档码
        subAddNewFileBatch: '0707000061', // 子页签-附件明细-批量更新附件
        subAddNewOpportunityPrivilege: '0707000062', // 子页签分公司明细-特权新增商机
        subDeleteCompanyPrivilege: '0707000063', // 子页签分公司明细-特权删除
        subAddSiteCompanyBatch: '0707000064', // 子页签分公司明细-批量添加服务平台
        subAddSiteCompanyPrivilege: '0707000065', // 子页签分公司明细-特权添加服务平台
        subDeleteSiteCompanyPrivilege: '0707000066', // 子页签分公司明细-特权删除服务平台
        subFlowAbandom: '2024080167', // 子页签分公司明细-特权删除服务平台
        otherSignature: '2025021368', // 主合同-提交第三方签章
        anntoSignature: '2025021369' // 主合同-提交智汇签验签
    },
    'contractGroupManage': { // 合同组管理
        addBatch: '1000000001',
        replenishBatch: '1000000002',
        stopBatch: '1000000003',
        delete: '1000000004',
        save: '1000000005',
        submitMip: '1000000006',
        addContractList: '1000000007',
        pushContractFW: '1000000008',
        saveContractList: '1000000009',
        deleteContractList: '10000000010'
    },
    'accountManage': {
        addNew: '0808000001', // 新增-按钮
        generate: '0808000002', // 生成账号-按钮
        enable: '0808000003', // 启用-按钮
        disable: '0808000004', // 停用-按钮
        export: '0808000005' // 导出-按钮
    },
    'contractArchive': {
        stop: '1100000001', // 停用
        export: '1100000002' // 导出
    },
    'contractView': {
        priceSubtab: '1200000001' // 价格表子页签权限
    },
    'contractRegistration': { // 续签合同登记表
        save: '1400000001',
        export: '1400000002',
        import: '1400000003'
    },
    'customer': {
        subStamperUse: '2024091910',
        subStamperStop: '2024091911',
        subStamperSync: '2024091912',
        subStamperLink: '2024091913',
        subStamper: '2024091914',
        subEcba: '2024111109',
        subEcbaAdd: '2024111110',
        subEcbaDel: '2024111111',
        subEcbaPush: '2024111112',
        subEcbaConfirm: '2024111113'
    },
    'customerPersonResponsible': { // 客户责任人归档表
        addNew: '1300000001',
        save: '1300000002',
        delete: '1300000003',
        export: '1300000004'
    },
    'companyManagerArchive': {
        export: '1500000001'
    },
    'opportunity': { // 商机模块
        opportunityListExport: '1600000001' // 商机表导出
    },
    'customerGroup': { // 集团客户模块
        export: '2024112814' // 导出
    }
};
const transportRelated = {
    'line': {
        add: '090400001',
        search: '090400002',
        export: '090400003',
        import: '090400004',
        edit: '090400005',
        enable: '090400006',
        disable: '090400007',
        distance: '090400008'
    },
    'carInspectionConfig': {
        add: '090600001',
        edit: '090600002',
        enable: '090600003',
        disable: '090600004'
    },
    'warehouseNetworkPlanning': {
        export: '091400101',
        import: '091400102',
        delete: '091400103',
        batchSave: '091400104',
        batchEnable: '091400105',
        batchDisable: '091400106',
        add: '091400107'
    }
};
const mappingControl = {
    'customerMapping': {
        add: '0901000001',
        batchSave: '0901000002',
        batchEnable: '0901000003',
        batchDisable: '0901000004',
        import: '0901000005'
    },
    'itemMapping': {
        add: '0902000001',
        batchSave: '0902000002',
        batchEnable: '0902000003',
        batchDisable: '0902000004'
    },
    'itemStatusMapping': {
        add: '0903000001',
        batchSave: '0903000002',
        batchEnable: '0903000003',
        batchDisable: '0903000004',
        import: '0903000005'
    },
    'warehouseMapping': {
        add: '0904000001',
        batchSave: '0904000002',
        batchEnable: '0904000003',
        batchDisable: '0904000004',
        import: '0904000005'
    },
    'orderTypeMapping': {
        add: '0905000001',
        batchSave: '0905000002',
        batchEnable: '0905000003',
        batchDisable: '0905000004'
    },
    'carrierMapping': {
        add: '0906000001',
        batchSave: '0906000002',
        batchEnable: '0906000003',
        batchDisable: '0906000004',
        import: '0906000005'
    },
    'warehouseSupplierMapping': {
        add: '0912000001',
        batchSave: '0912000002',
        batchEnable: '0912000003',
        batchDisable: '0912000004'
    },
    'upAndDownOrderTypeMapping': {
        add: '0907000001',
        batchSave: '0907000002',
        batchEnable: '0907000003',
        batchDisable: '0907000004'
    },
    'upAndDownWarehouseMapping': {
        add: '0908000001',
        batchSave: '0908000002',
        batchEnable: '0908000003',
        batchDisable: '0908000004'
    },
    'thirdPartyProviderMapping': {
        add: '0909000001',
        batchSave: '0909000002',
        batchEnable: '0909000003',
        batchDisable: '0909000004'
    }
};
const consumerMange = {
    'consumerMange': {
        Edit: '0602000001',
        apiConfig: '0602000002',
        checkDaily: '0602000003',
        handleDelete: '0602000004',
        siwtchOff: '0602000005',
        add: '0602000006',
        retry: '0602000007'
    }
};
const orderControl = {
    'receiveShippingContact': {
        import: '06280134001'
    }
};
const rulesRelated = {};
const internationalLogistics = {
    'port': {
        add: '130001001',
        export: '130001002',
        import: '130001003',
        col: '130001004',
        edit: '130001005'
    }
};
const midrangeArchitecture = {
    'orderNumble': {
        add: '1101000001',
        batchSave: '1101000002',
        batchEnable: '1101000003',
        batchDisable: '1101000004'
    }
};

const clientData = {
    'clientMapping': {
        add: '1201000001',
        batchSave: '1201000002',
        batchEnable: '1201000003',
        batchDisable: '1201000004',
        import: '1201000005'
    },
    'clientGroup': {
        add: '1202000001',
        edit: '1202000002'
    },
    'clientList': {
        add: '1203000001',
        batchSave: '1203000002',
        batchEnable: '1203000003',
        batchDisable: '1203000004',
        sync: '1203000005'
    },
    'clientDictionarie': {
        add: '1204000001',
        batchSave: '1204000002',
        batchEnable: '1204000003',
        batchDisable: '1204000004'
    }
};

export default {
    ...exportSales,
    ...consumerMange,
    ...systemManagement,
    ...warehouseRelated,
    ...commodityRelated,
    ...addressRelated,
    ...baseFunction,
    ...customerRelated,
    ...transportRelated,
    ...mappingControl,
    ...orderControl,
    ...internationalLogistics,
    ...rulesRelated,
    ...midrangeArchitecture,
    ...clientData
};
