import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 查询lcrm商机列表
export const opportunityInfoPageApi = (data) => {
    return request({
        url: apiCrmHost + '/opportunity/info/page',
        method: 'post',
        data
    });
};
// 查询IT资源对接申请详情
export const queryResourceApplication = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/resourceApplication/query',
        method: 'post',
        data
    });
};
