/**
 * 通用导出组件
 */
import request from '@/utils/http';
import { dictWithUrl } from './api';
const EXPORT_API = '/api-lcp-report/report/task/export';

// 字符转数组
// function arrayStrToArray(obj) {
//     return Object.entries(obj).reduce((result, i) => {
//         if (i[1] !== '') {
//             result[i[0]] = `${i[1]}`.indexOf(',') > -1 ? i[1].split(',') : i[1];
//         }
//         return result;
//     }, {});
// }
const reportExport = {
    render() {
        return this.$scopedSlots.default({
            submit: this.submit
        });
    },
    data() {
        return {};
    },
    props: ['reportCode'],
    methods: {
        async submit(searchData) {
            if (!this.reportCode) {
                this.$message.error('通用导出组件reportCode属性未配置');
                return;
            }
            const dictRes = await dictWithUrl(this.reportCode);
            if (!dictRes || dictRes.code !== '0') return;
            let params = dictRes.data[this.reportCode] || {};
            params = { ...params, ...searchData };
            Reflect.deleteProperty(params, 'pageSize');
            Reflect.deleteProperty(params, 'pageNo');
            const reportRes = await request.post(EXPORT_API, params);
            if (reportRes && reportRes.code === '0') {
                this.$message.success('已发送导出请求,请稍后到右上角用户文件列表中下载');
            }
        }
    }
};

export default reportExport;
