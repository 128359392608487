export const config = {
    name: 'industryList',
    searchFields: [
        {
            name: '项目编码',
            value: 'projectCode',
            type: 'input',
            isFixed: true,
            span: 4
        },
        {
            name: '商机编码',
            value: 'opportunityCode',
            type: 'input',
            isFixed: true,
            span: 4
        },
        {
            name: '客户',
            value: 'opportunityCustomerNames',
            type: 'advanceMulti',
            advanceConfig: [
                { prop: 'customerName', value: '客户名称' },
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerGroupName', value: '客户系' }
            ],
            tableConfig: [
                { prop: 'customerName', value: '客户名称', 'displayName': 'customerName' },
                { prop: 'customerCode', value: '客户编码' },
                { prop: 'customerGroupName', value: '客户系' }
            ],
            method: 'post',
            prefix: '/api-lcrm',
            advanceUrl: '/customer/info/page',
            cbParams: [ 'customerCode#opportunityCustomerCode', 'customerName#opportunityCustomerName' ],
            isFixed: true,
            span: 4
        },
        {
            name: '销售阶段',
            value: 'saleStages',
            optionNum: 'NEOCRM_SALE_STAGE',
            type: 'select',
            multiple: true,
            span: 4,
            isFixed: true
        },
        {
            name: '分公司',
            value: 'companyName',
            type: 'advance',
            // maxNum: 10, // 多选条数限制
            isFixed: true,
            span: 4,
            advanceConfig: [
                { 'prop': 'companyNameCn', 'value': '分公司名称' },
                { 'prop': 'companyCode', 'value': '分公司编码' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'companyNameCn', 'value': '分公司名称', 'displayName': 'companyNameCn' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popCompany`,
            cbParams: [ 'companyCode', 'companyNameCn#companyName' ]
        }
    ],
    selection: false,
    index: false,
    tableData: {
        columns: [
            {
                label: '单据类型',
                prop: 'opportunityEntityType',
                type: 'select',
                optionsKey: 'NEOCRM_OPPORTUNITY_ENTITY_TYPE',
                width: 100
            },
            {
                label: '项目编码',
                prop: 'projectCode',
                width: 160
            },
            {
                label: '项目名称',
                prop: 'projectName',
                width: 160
            },
            {
                label: '商机编码',
                prop: 'opportunityCode',
                width: 130
            },
            {
                label: '商机名称',
                prop: 'opportunityName',
                width: 130
            },
            {
                label: '分公司编码',
                prop: 'companyCode',
                width: 140
            },
            {
                label: '分公司名称',
                prop: 'companyName',
                width: 140
            },
            {
                label: '仓库类型',
                prop: 'warehouseType',
                type: 'select',
                optionsKey: 'NEOCRM_WH_TYPE',
                width: 120
            },
            {
                label: '商机客户编码',
                prop: 'opportunityCustomerCode',
                width: 130
            },
            {
                label: '商机客户名称',
                prop: 'opportunityCustomerName',
                width: 130
            },
            {
                label: '商机类型',
                prop: 'opportunityType',
                type: 'select',
                optionsKey: 'NEOCRM_OPPORTUNITY_TYPE',
                width: 100
            },
            {
                label: '实际中标规模(万)',
                prop: 'totalScale',
                width: 120
            },
            {
                label: '目前规模(万)',
                prop: 'businessScale',
                width: 100
            },
            {
                label: '签单规模(万)',
                prop: 'cdExpectBiddingSize',
                width: 100
            },
            {
                label: '销售阶段',
                prop: 'saleStage',
                type: 'select',
                optionsKey: 'NEOCRM_SALE_STAGE'
            },
            {
                label: '中标日期',
                prop: 'winningDate',
                width: 160
            },
            {
                label: '合同搜索时间',
                prop: 'contractSearchDate',
                width: 160
            },
            {
                label: '责任人美信号',
                prop: 'ownerCode',
                width: 100
            },
            {
                label: '责任人名称',
                prop: 'ownerName',
                width: 100
            },
            {
                label: '校验客户主体',
                prop: 'isCheck',
                type: 'select',
                optionsKey: 'NEOCRM_YES_NO', // 是否：1是2否,
                width: 100
            },
            {
                label: '报价项目类型',
                prop: 'projectType',
                type: 'select',
                // options: [ // TODO
                //     { label: '非标项目', value: '1' },
                //     { label: '标准产品项目', value: '2' },
                //     { label: '待定', value: '3' }
                // ],
                options: {
                    1: '非标项目',
                    2: '标准产品项目',
                    3: '待定'
                },
                width: 100
            },
            {
                label: '校验关联商机',
                prop: 'isLinkOppo',
                type: 'select',
                optionsKey: 'NEOCRM_YES_NO', // 是否：1是2否,
                width: 100
            },
            {
                label: '输单阶段',
                prop: 'lostStage',
                type: 'select',
                optionsKey: 'NEOCRM_SALE_STAGE'
            },
            {
                label: '报签确认',
                prop: 'isSign',
                type: 'select',
                optionsKey: 'NEOCRM_YES_NO'
            }
        ]
    }
};
